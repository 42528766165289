import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { ReactComponent as Help } from "../icons/Help-Icon.svg";
import { useTranslation } from "react-i18next";

function HelpButton({ footerRef, contentRef }) {
  const { t } = useTranslation("homepage");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonBottom, setButtonBottom] = useState(96); // Default for desktop

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const updateButtonPosition = () => {
      const isMobile = window.innerWidth < 768;

      if (isMobile && footerRef?.current) {
        const footerTop = footerRef.current.getBoundingClientRect().top;
        const viewportHeight = window.innerHeight;

        if (footerTop < viewportHeight) {
          // Footer is visible in the viewport; adjust button above it
          setButtonBottom(viewportHeight - footerTop + 16);
        } else {
          // Standard bottom spacing for mobile
          setButtonBottom(96);
        }
      } else if (contentRef?.current) {
        // For desktop, ensure button placement considers content height
        const contentBottom = contentRef.current.getBoundingClientRect().bottom;
        const viewportHeight = window.innerHeight;

        if (contentBottom < viewportHeight) {
          // Content is shorter than viewport; adjust button to page bottom
          setButtonBottom(viewportHeight - contentBottom + 16);
        } else {
          // Standard bottom spacing for desktop
          setButtonBottom(96);
        }
      }
    };

    updateButtonPosition();
    window.addEventListener("resize", updateButtonPosition);

    return () => {
      window.removeEventListener("resize", updateButtonPosition);
    };
  }, [footerRef, contentRef]);

  return (
    <>
      <Button
        type="primary"
        shape="circle"
        icon={<Help size={28} />}
        onClick={showModal}
        style={{
          position: "fixed",
          bottom: `${buttonBottom}px`,
          right: "16px",
          width: "50px",
          height: "50px",
          zIndex: 1000,
        }}
        className="z-20 text-white help-btn-ok"
      />
      <Modal
        title="Help"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Close"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{
          className: "help-btn-ok",
        }}
      >
        <p>
          {t("homepage:contactMessage")}{" "}
          <a
            href="https://wa.me/46729208253"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-400 hover:underline"
          >
            {t("homepage:whatsappMessage")}
          </a>
        </p>
      </Modal>
    </>
  );
}

export default HelpButton;
