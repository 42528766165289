import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader";
import { ReactComponent as Plus } from "../../icons/Plus-Icon.svg";
import { ReactComponent as Favourite } from "../../icons/Heart-Icon-Outline.svg";
import { ReactComponent as Sell } from "../../icons/Coin-Icon-Outline.svg";
import { ReactComponent as Delivery } from "../../icons/Delivery-Icon.svg";
import { ReactComponent as Warning } from "../../icons/Warning-Icon.svg";
import { ReactComponent as Clipboard } from "../../icons/Clipboard-Icon.svg";
import { ReactComponent as History } from "../../icons/History-Icon.svg";
import { ReactComponent as Discount } from "../../icons/Discount-Icon.svg";
import { AdminContext } from "./util/AdminContext";
import { postReq } from "../../configs/ApiUrl.js";
import AlwaysTop from "./util/AlwaysTop";

function AdminMenu() {
  const { t } = useTranslation(["admin", "altText", "userProfile"]);
  const navigateLogout = useNavigate();
  const { setIsAdmin } = useContext(AdminContext);
  const [loading, setLoading] = useState(false);

  const handleLogOut = async () => {
    try {
      setLoading(true);
      const logOutResp = await postReq("/user/logout");
      if (logOutResp?.success) {
        setLoading(false);
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("u");
        localStorage.removeItem("r");
        navigateLogout("/admin");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    AlwaysTop();
  }, []);

  const adminLinks = [
    {
      to: "/admin-add-product",
      icon: (
        <Plus
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:addProductButton"),
    },
    {
      to: "/admin-product-table",
      icon: (
        <Favourite
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:approvalButton"),
    },
    {
      to: "/live-listings",
      icon: (
        <Sell
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:liveButton"),
    },
    {
      to: "/delivery-needed",
      icon: (
        <Warning
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:deliveryNeededButton"),
    },
    {
      to: "/active-orders",
      icon: (
        <Clipboard
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:activeButton"),
    },
    {
      to: "/delivery-status",
      icon: (
        <Delivery
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:deliveryStatusButton"),
    },
    {
      to: "/products-history",
      icon: (
        <History
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:historyButton"),
    },
    {
      to: "/discount-codes",
      icon: (
        <Discount
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:discountButton"),
    },
    {
      to: "/admin-register-new",
      icon: (
        <Plus
          style={{ width: "72px", height: "72px" }}
          className='mb-4 transition duration-200'
        />
      ),
      label: t("admin:registerNewAdminButton"),
    },
  ];

  return (
    <div className='bg-gray-100 min-h-screen'>
      <header className='flex flex-col items-center justify-center mt-4 p-6 font-bold text-lg bg-white shadow-md w-full'>
        <PageHeader />
        <h1 className='mt-12 text-3xl font-bold text-center'>
          {t("admin:menuTitle")}
        </h1>
        <img
          alt={t("altText:horizontalDivider")}
          src='./../assets/icons/divider-horizontal.svg'
          className='w-40 sm:w-60 mt-2'
        />
      </header>
      <div className='max-w-screen-xl mx-auto px-6 py-12'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
          {adminLinks.map((link, index) => (
            <Link
              to={link.to}
              key={index}
              className='group cursor-pointer'>
              <div className='bg-white shadow-lg rounded-lg p-8 flex flex-col items-center transition duration-300 hover:bg-cromboOrange hover:text-white hover:shadow-xl w-full h-full'>
                {React.cloneElement(link.icon, {
                  className:
                    "text-gray-700 group-hover:text-white transition duration-200",
                })}
                <h3 className='text-2xl text-center font-semibold text-gray-800 group-hover:text-white mb-2 transition duration-200'>
                  {link.label}
                </h3>
                <div className='h-1 w-16 bg-cromboOrange group-hover:bg-white transition duration-200'></div>
              </div>
            </Link>
          ))}
        </div>

        <div className='flex justify-center mt-10'>
          <button
            onClick={handleLogOut}
            className={`bg-red-600 text-white py-3 px-8 rounded-lg font-semibold shadow-lg hover:bg-red-700 transition duration-200 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}>
            {loading ? "Logging out..." : t("userProfile:logoutButton")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminMenu;
