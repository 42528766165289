import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Buy } from "../icons/Store-Icon.svg";
import { ReactComponent as Sell } from "../icons/Coin-Icon-Outline.svg";
import { ReactComponent as Favourite } from "../icons/Heart-Icon-Outline.svg";
import { ReactComponent as ShoppingCart } from "../icons/Shopping Cart Icon.svg";

function CatalogueFooter() {
  const location = useLocation();
  const { t } = useTranslation();

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    // Get the footer element by its ID
    const footerElement = document.getElementById("catalogue-footer");

    // Function to update the body's padding-bottom
    const updateBodyPadding = () => {
      if (footerElement) {
        const footerHeight = footerElement.offsetHeight;
        document.body.style.paddingBottom = `${footerHeight}px`;
      }
    };

    // Initial padding adjustment
    updateBodyPadding();

    // Adjust padding when the window is resized
    window.addEventListener("resize", updateBodyPadding);

    // Cleanup function
    return () => {
      document.body.style.paddingBottom = null;
      window.removeEventListener("resize", updateBodyPadding);
    };
  }, []);

  return (
    <div
      id='catalogue-footer'
      className='fixed bottom-0 inset-x-0 bg-white flex justify-between px-8 py-4 shadow-md z-10'>
      <Link
        to='/shopping-home'
        id='1'
        className={`flex flex-col items-center hover:text-cromboOrangeFade ${
          isActive("/shopping-home") ? "text-cromboOrange" : "text-black"
        }`}>
        <Buy id='1' />
        <p className='font-bold text-xs mt-1'>{t("homepage:buyButton")}</p>
      </Link>
      <Link
        to='/selling-add-furniture'
        id='2'
        className={`flex flex-col items-center hover:text-cromboOrangeFade ${
          isActive("/selling-add-furniture")
            ? "text-cromboOrange"
            : "text-black"
        }`}>
        <Sell id='2' />
        <p className='font-bold text-xs mt-1'>{t("homepage:sellButton")}</p>
      </Link>
      <Link
        to='/catalogue-favourites'
        id='3'
        className={`flex flex-col items-center hover:text-cromboOrangeFade ${
          isActive("/catalogue-favourites") ? "text-cromboOrange" : "text-black"
        }`}>
        <Favourite id='3' />
        <p className='font-bold text-xs mt-1'>
          {t("homepage:favouriteButton")}
        </p>
      </Link>
      <Link
        to='/catalogue-cart'
        id='4'
        className={`flex flex-col items-center hover:text-cromboOrangeFade ${
          isActive("/catalogue-cart") ? "text-cromboOrange" : "text-black"
        }`}>
        <ShoppingCart id='4' />
        <p className='font-bold text-xs mt-1'>{t("homepage:cartButton")}</p>
      </Link>
    </div>
  );
}

export default CatalogueFooter;
