import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageSubheader from "../PageSubheader.js";
import { ReactComponent as Delete } from "../../icons/Trash-Icon.svg";
import { ReactComponent as Edit } from "../../icons/Edit-Icon.svg";
import { useTranslation } from "react-i18next";
import { getReq, delReq } from "../../configs/ApiUrl";
import { FurnitureSearch } from "../../App.js";
import { LoadingSpinner } from "./util/LoadingSpinner";
import DeleteWarningModal from "./util/DeleteWarningModal.js";
import AlwaysTop from "./util/AlwaysTop.js";

function AdminProductTable() {
  const { t } = useTranslation(["admin", "altText"]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [pendingProducts, setPendingProducts] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleDelete = async (id) => {
    try {
      setLoadingStates((prevState) => ({ ...prevState, [id]: true }));
      const updatedProductResp = await delReq(`/product/history/${id}`, { status: "declined" });
      if (updatedProductResp?.success) {
        await getAllPendingProducts(); // Refresh the table after deletion
      }
      setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
    } catch (error) {
      console.error("Error deleting product:", error);
      setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
    }
  };

  const getAllPendingProducts = async () => {
    try {
      setLoading(true);
      const allProductsResp = await getReq("/product/all");
  
      if (allProductsResp?.allProducts) {
  
        const filteredProducts = allProductsResp.allProducts.filter(
          (product) => product.status === "pending"
        );
  
        setPendingProducts(filteredProducts);
      } else {
        setPendingProducts([]);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate("/admin");
      }
    } finally {
      setLoading(false);
    }
  };  

  const initializeLoadingState = (productId) => {
    setLoadingStates((prevState) => ({ ...prevState, [productId]: false }));
  };

  useEffect(() => {
    pendingProducts.forEach((product) => initializeLoadingState(product._id));
  }, [pendingProducts]);

  useEffect(() => {
    getAllPendingProducts();
  }, []);

  useEffect(() => {
    AlwaysTop();
  }, []);

  const openDeleteModal = (id) => {
    setSelectedProductId(id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedProductId(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    if (selectedProductId) {
      await handleDelete(selectedProductId);
      closeDeleteModal();
      await getAllPendingProducts(); // Ensure the table is refreshed after closing the modal
    }
  };

  return (
    <div className="flex flex-col items-center">
        <PageSubheader
        backLink="/admin-menu"
        title={t("admin:approvalButton")}
        description={t("admin:approvalExplainer")}
      />
      {showDeleteModal && (
        <DeleteWarningModal 
          onConfirm={handleConfirmDelete} 
          onClose={closeDeleteModal} 
        />
      )}

      <div className="flex flex-col items-center px-2">
        <main className="w-full flex flex-col justify-center mb-10 bg-white drop-shadow overflow-x-auto">
          <div className="overflow-x-auto">
            {loading ? (
              <LoadingSpinner />
            ) : pendingProducts.length > 0 ?  (
              <table className="table-auto w-full border-collapse rounded-t-xl -p-1 text-center">
                <thead>
                  <tr>
                    <th className="px-2 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold">
                      {t("admin:item")}
                    </th>
                    <th className="px-2 border-b-4 border-cromboOrangeFade text-black font-semibold">
                      {t("admin:actionHeader")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pendingProducts.length === 0 ? (
                    <tr>
                      <td colSpan="2" className="text-center text-gray-400 py-48 px-48">
                        {t("admin:noProducts")}
                      </td>
                    </tr>
                  ) : (
                    pendingProducts.map((currentObject) => (
                      <tr key={currentObject._id} className="hover:bg-background">
                        <td className="relative border-b-4 border-cromboOrangeFade">
                          <div className="flex flex-col items-center">
                            <img
                              className="w-full max-w-sm h-auto mx-auto"
                              src={currentObject.imageNames[0]}
                              alt={t("altText:firstImage")}
                            />
                            <div className="table-overlay">
                              <span className="truncate text-sm">
                                {currentObject.title.length > 20
                                  ? currentObject.title.substring(0, 15) + "..."
                                  : currentObject.title}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-2 bg-background border-2 border-b-4 border-b-cromboOrangeFade border-r text-slate-500 border-white text-center">
                          {loadingStates[currentObject._id] ? (
                            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900 "></div>
                          ) : (
                            <div className="flex flex-col sm:flex-row justify-center items-center gap-4 ">
                              <div
                                className="flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-cromboOrange hover:text-cromboOrangeFade cursor-pointer drop-shadow"
                                onClick={() => {
                                  let selection = currentObject._id;
                                  let filter = pendingProducts.filter((x) => x._id === selection);
                                  setSearchTerm({ ...searchTerm, adminSearch: filter });
                                  navigate("/admin-product-view");
                                }}
                              >
                                <Edit className="h-6 w-6" id={currentObject._id} />
                                <h4 className="ml-2 text-sm font-bold pointer-events-none">{t("editButton")}</h4>
                              </div>

                              <div
                                className="flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-red-600 hover:text-red-700 cursor-pointer drop-shadow"
                                onClick={() => openDeleteModal(currentObject._id)}
                              >
                                <Delete className="h-6 w-6" />
                                <h4 className="ml-2 text-sm font-bold">{t("deleteButton")}</h4>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            ) : (
            <div className="flex items-center justify-center w-96 h-96">
              <h1 className="text-xl font-bold text-center text-gray-500">
                  {t("admin:noOrders")}
              </h1>
            </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default AdminProductTable;