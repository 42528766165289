import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getReq, postReq } from "../../configs/ApiUrl.js";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";
import {
  MdKeyboardArrowRight,
  MdAdminPanelSettings,
  MdLogout,
} from "react-icons/md";
import { ReactComponent as Sell } from "../../icons/Coin-Icon-Outline.svg";
import { ReactComponent as History } from "../../icons/History-Icon.svg";
import { ReactComponent as Profile } from "../../icons/User-Circle-Icon.svg";
import { ReactComponent as Logout } from "../../icons/Logout-Icon.svg";
import { FaTruck } from "react-icons/fa";

function UserProfile() {
  const { t } = useTranslation(["userProfile", "altText", "subadmin"]);
  const navigateLogout = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isSubadmin, setIsSubadmin] = useState(false);

  useEffect(() => {
    const fetchUserRole = async () => {
        try {
            const userId = localStorage.getItem("userId"); // Retrieve user ID from localStorage
            if (!userId) {
                return;
            }

            const roleResponse = await getReq(`/user/${userId}`);
            if (roleResponse?.success && roleResponse.role) {
                const { role } = roleResponse;
                setUserInfo({ role });
                if (role === "subadmin") {
                    setIsSubadmin(true);
                }
            } else {
                throw new Error("Role not found");
            }
        } catch (error) {
            console.error("Error accessing user role:", error);
        }
    };

    fetchUserRole();
}, []);


  const handleLogOut = async () => {
    try {
      setLoading(true);
      const logOutResp = await postReq("/user/logout");
      if (logOutResp?.success) {
        setLoading(false);
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem('u');
        navigateLogout("/welcome-login");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  if (!userInfo) {
    return <div>Loading...</div>; // Display a loading message while userInfo is being fetched
  }

  return (
    <div className='flex flex-col items-center min-h-screen'>
      <div className='w-full relative'>
        <header className='mt-20'>
          <article>
            <PageHeader />
          </article>
        </header>
        {/* Menu providing links to page components to update user contact information, to view items to be bought or sold
                and a logout. */}
        <main className='-mt-4'>
          <div className='drop-shadow bg-white pb-2'>
            <Link to='/user-contact'>
              <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                <div className='flex items-center'>
                  <Profile
                    className='mr-4 w-12 h-12'
                    />
                  <h2 className='text-lg font-semibold'>
                    {t("contactInfoButton")}
                  </h2>
                </div>
                <div className='flex items-center ml-auto hover:text-cromboOrangeFade'>
                  <MdKeyboardArrowRight
                    className='text-cromboOrange'
                    size={45}
                  />
                </div>
              </button>
            </Link>
            <hr className='mx-4 border-t-1 border-gray-200' />
            <Link to='/approved-delivery'>
              <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                <div className='flex items-center'>
                  <FaTruck
                    className='mr-4'
                    size={45}
                  />
                  <h2 className='text-lg font-semibold'>
                    {t("approvedDeliveryButton")}
                  </h2>
                </div>
                <div className='flex items-center ml-auto hover:text-cromboOrangeFade'>
                  <MdKeyboardArrowRight
                    className='text-cromboOrange'
                    size={45}
                  />
                </div>
              </button>
            </Link>
            <hr className='mx-4 border-t-1 border-gray-200' />
            <Link to='/user-buy'>
              <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                <div className='flex items-center'>
                  <History
                    className='mr-4 w-12 h-12'
                  />
                  <h2 className='text-lg font-semibold'>
                    {t("productsBoughtButton")}
                  </h2>
                </div>
                <div className='flex items-center ml-auto'>
                  <MdKeyboardArrowRight
                    className='text-cromboOrange hover:text-cromboOrangeFade'
                    size={45}
                  />
                </div>
              </button>
            </Link>
            <hr className='mx-4 border-t-1 border-gray-200' />
            <Link to='/user-sell'>
              <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                <div className='flex items-center'>
                  <Sell
                    className='mr-4 w-12 h-12'
                  />
                  <h2 className='text-lg font-semibold'>
                    {t("productSaleButton")}
                  </h2>
                </div>
                <div className='flex items-center ml-auto'>
                  <MdKeyboardArrowRight
                    className='text-cromboOrange hover:text-cromboOrangeFade'
                    size={45}
                  />
                </div>
              </button>
            </Link>
            <hr className='mx-4 border-t-1 border-gray-200' />
            {isSubadmin && (
              <Link to='/subadmin-menu'>
                <button className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-cromboOrangeFade'>
                  <div className='flex items-center'>
                    <MdAdminPanelSettings
                      className='mr-4'
                      size={45}
                    />
                    <h2 className='text-lg font-semibold'>
                      {t("subadmin:subadminPortal")}
                    </h2>
                  </div>
                  <div className='flex items-center ml-auto'>
                    <MdKeyboardArrowRight
                      className='text-cromboOrange hover:text-cromboOrangeFade'
                      size={45}
                    />
                  </div>
                </button>
              </Link>
            )}
            <hr className='mx-4 border-t-1 border-gray-200' />
            <Link>
              <button
                onClick={handleLogOut}
                className='rounded-2xl flex items-center justify-between my-2 w-full px-4 py-2 hover:text-red-400'>
                <div className='flex items-center'>
                  <Logout
                    className='mx-2 mr-3 w-10 h-10 text-red-600'
                  />
                  <h2 className='text-red-600 text-lg font-semibold'>
                    {t("logoutButton")}
                  </h2>
                </div>
              </button>
            </Link>
          </div>
        </main>
        {/* Conditionally render PageFooter on mobile screens */}
        <div className='block md:hidden'>
          <PageFooter />
        </div>{" "}
      </div>
    </div>
  );
}

export default UserProfile;
