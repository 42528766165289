import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { ReactComponent as Favourite } from "../../icons/Heart-Icon-Outline.svg";
import PageHeader from "../PageHeader.js";
import PageFooter from "../PageFooter.js";
import { FurnitureSearch } from "../../App.js";
import FurnitureCard from "../FurnitureCard.js";
import { getReq, postReq } from "../../configs/ApiUrl.js";
import { useTranslation } from "react-i18next";
import AlwaysTop from "./util/AlwaysTop.js";

function CatalogueFavourites() {
  const { t } = useTranslation("favouritesPage");
  const [favouriteProducts, setFavouriteProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const navigateProduct = useNavigate();

  const getFavouriteProducts = async () => {
    try {
      setLoading(true);
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID not found in local storage.");
        return;
      }

      // Fetch favourite product IDs
      const response = await getReq(`/user/${userId}/get-favourite`);
      if (
        response &&
        response.success &&
        Array.isArray(response.favouriteProducts)
      ) {
        // Fetch details for each product
        const productDetails = await Promise.all(
          response.favouriteProducts.map(async (productId) => {
            const productResponse = await getReq(`/product/${productId}`);
            return productResponse.product; // Accessing the actual product data inside the response
          })
        );
        setFavouriteProducts(productDetails);
      } else {
        setFavouriteProducts([]);
      }
    } catch (error) {
      console.error("Error fetching favourite products:", error);
      setFavouriteProducts([]); // Ensure favouriteProducts is an array even on error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFavouriteProducts();
    AlwaysTop();
  }, []);

  const handleProductSelect = (productId) => {
    setSearchTerm({
      ...searchTerm,
      searchFilter: favouriteProducts.filter((x) => x._id === productId),
      productDisplayMain: "hidden",
      productDisplayZoom: "visible",
      productArrow: "hidden",
      generalArrow: "visible",
      arrowNavigation: "/catalogue-favourites",
      searchID: productId,
    });

    navigateProduct(`/catalogue-product/${productId}`);
  };

  const removeFavourite = async (index, productId) => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        console.error("User ID not found in local storage.");
        return;
      }

      const response = await postReq("/user/remove-favourite", {
        userId,
        productId,
      });

      if (response && response.message) {
        console.log("Product removed from favourites:", response.message);

        let updatedFavourites = [...favouriteProducts];
        updatedFavourites.splice(index, 1);
        setFavouriteProducts(updatedFavourites);

        let updatedSearchTermFavourites = [...searchTerm.favourites];
        updatedSearchTermFavourites = updatedSearchTermFavourites.filter(
          (id) => id !== productId
        );
        setSearchTerm({
          ...searchTerm,
          favourites: updatedSearchTermFavourites,
        });
      } else {
        console.error("Failed to remove product from favourites.");
      }
    } catch (error) {
      console.error("Error removing product from favourites:", error);
    }
  };

  return (
    <div className='flex flex-col items-center min-h-screen bg-[#F4F5F9]'>
      <PageHeader />
      <div className='w-full max-w-screen-lg px-4 mt-24'>
        <main className='flex-grow'>
          {favouriteProducts.length === 0 ? (
            <div className='flex flex-col items-center justify-center mt-24 pb-24'>
              <Favourite
                width={170}
                height={170}
                className="text-cromboOrange"
              />
              <h2 className='text-center text-2xl p-2 font-bold'>
                {t("favSubheader")}
              </h2>
              <p className='p-2 text-center text-stone-400'>{t("favEmpty")}</p>
            </div>
          ) : (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
              {favouriteProducts.map(
                (currentObject, i) =>
                  currentObject && (
                    <div
                      className='relative bg-white drop-shadow'
                      key={i}
                      onClick={() => handleProductSelect(currentObject?._id)}>
                      <FurnitureCard
                        key={currentObject?._id}
                        id={currentObject?._id}
                        name={currentObject?.title}
                        price={
                          currentObject?.price != null ? currentObject.price : 0
                        }
                        url={
                          Array.isArray(currentObject?.imageNames) &&
                          currentObject.imageNames.length > 0
                            ? currentObject.imageNames[0]
                            : "default-image-url.jpg"
                        }
                        moving={currentObject?.deliveryPrice ?? 0}
                        condition={currentObject?.condition}
                        showCondition={false}
                        verified={currentObject?.isQualityVerified}
                      />
                      <div className='text-red-500 hover:text-red-400 absolute right-6 top-5 cursor-pointer z-10'>
                        <div
                          className='border-4 bg-white border-white rounded-lg'
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents triggering the product select event
                            removeFavourite(i, currentObject._id);
                          }}>
                          <IoMdClose
                            size={20}
                            key={i}
                            id={i}
                            name='removeItem'
                          />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
        </main>
      </div>
      {/* Conditionally render PageFooter on mobile screens */}
      <div className='block md:hidden'>
        <PageFooter />
      </div>
    </div>
  );
}

export default CatalogueFavourites;
